export const pusherChannels = {
  importsStream: "imports-stream",
  reportsStream: "reports-stream",
};

export const pusherEvents = {
  importItemUpdated: "import-item-updated",
  importItemError: "import-item-error",
  reportUpdated: "report-updated",
  reportError: "report-error",
  sync: "sync",
  replacementSync: "replacement-sync",
};
