import { useCallback } from "react";

export const useYupValidationResolver = (validationSchema: any) =>
  useCallback(
    async (data: any) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors: any) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors: any, currentError: { path: any; type: any; message: any }) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {},
          ),
        };
      }
    },
    [validationSchema],
  );

type Adress = {
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
};

export const getFullAddress = <T extends Adress>(item: T) => {
  let fullAddress = "";

  if (item.address1) fullAddress += `${item.address1}, `;
  if (item.address2) fullAddress += `${item.address2}, `;

  if (item.city && item.state) {
    fullAddress += `${item.city}, ${item.state} `;
  } else {
    if (item.city) fullAddress += `${item.city} `;
    if (item.state) fullAddress += `${item.state} `;
  }
  if (item.postalCode) fullAddress += `${item.postalCode} `;

  return fullAddress;
};
