import { EquipmentType } from "..";

const getEquipmentIdPropertyNameFromType = (equipmentType: EquipmentType) => {
  switch (equipmentType) {
    case EquipmentType.PANEL:
      return "panelId";

    case EquipmentType.SOLAR_ARRAY:
      return "solarArrayId";

    case EquipmentType.SWITCHBOARD:
      return "switchboardId";

    case EquipmentType.TRANSFORMER:
      return "transformerId";

    case EquipmentType.UTILITY_CONNECTION:
      return "utilityConnectionId";

    case EquipmentType.METER:
      return "meterId";

    case EquipmentType.GENERATOR:
      return "generatorId";

    case EquipmentType.DCAC_INVERTER:
      return "dCACInvertersId";

    case EquipmentType.JUNCTION_BOX:
      return "junctionBoxId";

    case EquipmentType.AUTOMATIC_TRANSFER_SWITCH:
      return "automaticTransferSwitchesId";

    case EquipmentType.DISCONNECT:
      return "disconnectsId";

    case EquipmentType.EV_CHARGER:
      return "eVChargersId";

    case EquipmentType.GENERIC_LOAD:
      return "genericLoadId";

    case EquipmentType.MOTOR:
      return "motorId";

    case EquipmentType.VARIABLE_FREQUENCY_DRIVE:
      return "variableFrequencyDriveId";

    default:
      throw new Error("Invalid equipment type");
  }
};

export { getEquipmentIdPropertyNameFromType };
