import React from "react";
import { FiAlertTriangle } from "react-icons/fi"; // You can choose your preferred icon library

const ErrorComponent = ({ title, message, buttonText, buttonAction }) => {
  return (
    <div className="bg-white border border-red-500 text-red-500 p-4 rounded-md shadow-md flex items-center">
      <div className="mr-4">
        <FiAlertTriangle className="text-4xl" />
      </div>
      <div>
        <h2 className="text-2xl font-semibold mb-2">{title}</h2>
        <p className="mb-4 whitespace-pre-line">{message}</p>
        {buttonAction && (
          <button
            onClick={buttonAction}
            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded focus:outline-none"
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default ErrorComponent;
