import { Button, Image } from "@chakra-ui/react";
import { useRouter } from "next/navigation";
import React from "react";

const LogoButton = ({ inverse = false }) => {
  const router = useRouter();
  return (
    <Button
      _hover={{
        bg: "none",
        color: "inherit",
        boxShadow: "none",
      }}
      variant="ghost"
      onClick={() => {
        router.push("/");
      }}
      w="100%"
    >
      <div className="absolute flex items-center gap-2">
        <Image
          src={inverse ? "/images/Condoit_Logo_Reverse.png" : "/images/Condoit-Color-Logo-1.png"}
          alt="condoit"
          width={150}
        />
      </div>
    </Button>
  );
};

export default LogoButton;
