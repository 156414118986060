import dotenv from "dotenv";
import { isNil } from "lodash";
import { EnvironmentService } from "./Environment.service";

dotenv.config();

export class UIEnvironmentService extends EnvironmentService {
  HOST: string;
  STRIPE_KEY_FE: string;
  PKEY: string;
  S3_PUBLIC_BUCKET: string;
  GOOGLE_MAPS_API_KEY: string;
  RESIZE_PHOTO_URL_PREFIX: string;
  VIDEO_THUMBNAIL_URL_PREFIX: string;
  FILE_THUMBNAIL_URL_PREFIX: string;
  PUSHER_KEY: string;
  PUSHER_CLUSTER: string;
  SUPPORT_EMAIL_ADDRESS: string;

  constructor() {
    super();

    let domain = isNil(this.config.HOST) ? process.env.VERCEL_URL : this.config.HOST;

    if (!isNil(domain) && !domain.includes("http")) {
      // If the domain is not nil and does not include http, add it
      domain = "https://" + domain;
    }

    this.HOST = domain || "";
    this.STRIPE_KEY_FE = this.config.STRIPE_KEY_FE || "";
    this.PKEY = this.config.PKEY || "";
    this.S3_PUBLIC_BUCKET = this.config.S3_PUBLIC_BUCKET || "";
    this.RESIZE_PHOTO_URL_PREFIX = this.config.RESIZE_PHOTO_URL_PREFIX || "";
    this.VIDEO_THUMBNAIL_URL_PREFIX = this.config.VIDEO_THUMBNAIL_URL_PREFIX || "";
    this.FILE_THUMBNAIL_URL_PREFIX = this.config.FILE_THUMBNAIL_URL_PREFIX || "";
    this.GOOGLE_MAPS_API_KEY = this.config.GOOGLE_MAPS_API_KEY || "";
    this.PUSHER_KEY = this.config.PUSHER_KEY || "";
    this.PUSHER_CLUSTER = this.config.PUSHER_CLUSTER || "";
    this.SUPPORT_EMAIL_ADDRESS = this.config.SUPPORT_EMAIL_ADDRESS || "";
  }
}

export const uiEnvironment = new UIEnvironmentService();
