import API from "@condoit-apps/services/src/utility/API.service";
import { User } from "@condoit-apps/services/src/data/UserData.service";
import { IDataResult } from "@condoit-apps/services/src/types/apiTypes";
import { Prisma, Users } from "@/models";

export async function updateUser(user: Users): Promise<Users> {
  const response = await API.put(`/api/users/${user.id}`, {
    body: JSON.stringify(user),
  });

  if (response.success !== true) throw new Error(response.message);

  return response.user as Users;
}

export async function deleteUser(userId: string) {
  const response = await API.delete(`/api/users/${userId}`);

  if (response.success !== true) throw new Error(response.message);

  return response.user;
}

export async function getAllUsers(searchParams?: string): Promise<User[]> {
  const response = await API.get(`/api/users${searchParams ? `?${searchParams}` : ""}`);

  if (response.success !== true) throw new Error(response.message);

  return response.users as User[];
}

export async function getUsersByCompanyId(companyId: string, searchParams?: string): Promise<IDataResult<any>> {
  const response = await API.get(`/api/company/${companyId}/users${searchParams ? `?${searchParams}` : ""}`);

  if (response.success !== true) throw new Error(response.message);

  return { users: response.users as User[], count: response.count };
}

export async function getCompaniesUsers(companyId: string, additionalCompanies: string[]): Promise<Users[]> {
  const response = await API.get(
    `/api/company/${companyId}/users?additionalCompanies=${additionalCompanies?.join(",")}`,
  );

  if (response.success !== true) throw new Error(response.message);

  return (response.users as Users[]) ?? [];
}

export async function getUserById(userId: string) {
  const response = await API.get(`/api/users/${userId}`);

  if (response.success !== true) throw new Error(response.message);

  return response.user as User;
}

export async function getCurrentUser() {
  const response = await fetch("/api/users/getUser", {});

  const data = await response.json();

  return {
    user: data?.user,
    company: data?.company,
    subscriptions: data?.subscriptions,
  };
}

export async function getCurrentUserNotificationPreferences() {
  const response = await API.get("/api/users/getUser/notification-preferences", {});

  if (response.success !== true) throw new Error(response.message);

  return response.userNotificationPreferences as Prisma.UsersGetPayload<{
    include: { notificationPreferences };
  }>["notificationPreferences"];
}

export async function updateUserNotificationPreferences(userId: string, userNotificationPreferences) {
  const response = await API.put(`/api/users/${userId}/notification-preferences`, {
    body: JSON.stringify(userNotificationPreferences),
  });

  if (response.success !== true) throw new Error(response.message);

  return response.notificationPreferences;
}

export async function registerUser(email, password, passwordConfirmation) {
  const response = await API.post("/api/auth/signup", {
    body: JSON.stringify({
      email,
      password,
      passwordConfirmation,
    }),
  });

  if (response.success !== true) throw new Error(response.message);

  return response.user;
}

export async function checkIfEmailExists(email: string) {
  const safeEmail = encodeURIComponent(email);
  const response = await API.get(`/api/users/check-email?email=${safeEmail}`);

  if (response.success !== true) throw new Error(response.message);

  return response.emailExists;
}

export async function sendEmailVerificationCode(email: string) {
  const safeEmail = encodeURIComponent(email);
  const response = await API.get(`/api/auth/send-email-verification-code?email=${safeEmail}`);

  if (response.success !== true) throw new Error(response.message);

  return response;
}

export async function verifyEmailVerificationCode(email: string, code: string) {
  const response = await API.post(`/api/auth/verify-email`, {
    body: JSON.stringify({
      email,
      code,
    }),
  });

  if (response.success !== true) throw new Error(response.message);

  return response;
}

export async function sendForgotPasswordCode(email: string) {
  const resetPath = "/api/auth/forgot-password";
  const response = await API.get(`${resetPath}?email=${encodeURIComponent(email)}`);

  if (response.success !== true) {
    throw new Error(response.message);
  }

  return response;
}

export async function verifyForgotPasswordCode(email: string, newPassword: string, code: string) {
  const resetPath = "/api/auth/forgot-password";
  const response = await API.post(`${resetPath}/verify-code?email=${encodeURIComponent(email)}`, {
    body: JSON.stringify({
      email,
      password: newPassword,
      code,
    }),
  });

  if (response.success !== true) {
    throw new Error(response.message);
  }

  return response;
}
