// biome-ignore lint/performance/noBarrelFile: <explanation>
export { config as devConfig } from "./dev.config";
export { config as prodConfig } from "./prod.config";
export { config as localConfig } from "./local.config";
export { config as stagingConfig } from "./staging.config";

export * from "./Environment.service";
export * from "./UIEnvironment.service";
export * from "./constants";
export * from "../environment";
