import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { ThemeConfig, extendTheme } from "@chakra-ui/react";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { Primary_Accent_color } from "./antTheme";
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  // label: {
  //   fontFamily: "mono", // change the font family of the label
  // },
  control: {
    borderColor: "brand.500",
    _checked: {
      background: "brand.500",
      border: "brand.500",
    },
  },
});

export const chakraTheme = extendTheme({
  colors: {
    secondary: {
      50: "#ffffff",
      100: "#ffffff",
      200: "#fdc8c8",
      300: "#fb7070",
      400: "#fa5252",
      500: Primary_Accent_color,
      600: "#eb0707",
      700: "#b00505",
      800: "#750303",
      900: "#3b0202",
    },
    primary: {
      50: "#ffffff",
      100: "#ffffff",
      200: "#fdc8c8",
      300: "#fb7070",
      400: "#fa5252",
      500: Primary_Accent_color,
      600: "#eb0707",
      700: "#b00505",
      800: "#750303",
      900: "#3b0202",
    },
    brand: {
      50: "#ffffff",
      100: "#ffffff",
      200: "#fdc8c8",
      300: "#fb7070",
      400: "#fa5252",
      500: Primary_Accent_color,
      600: "#eb0707",
      700: "#b00505",
      800: "#750303",
      900: "#3b0202",
    },
    red: {
      50: "#FFF5F5",
      100: "#FED7D7",
      200: "#FF7F7F",
      300: "#FF6262",
      400: "#FF4646",
      500: "#FF2A2A",
      600: "#FF0D0D",
      700: "#E60000",
      800: "#CC0000",
      900: "#B30000",
    },
    background: "white",
  },
  colorScheme: "brand",
  components: {
    Toast: {
      baseStyle: {
        zIndex: 99999999, // Your zIndex value
      },
    },
    RadioCard: {
      borderWidth: "1px",
      borderRadius: "lg",
      p: "4",
      bg: "brand.500",
      transitionProperty: "common",
      transitionDuration: "normal",
      _hover: { borderColor: "brand.300" },
      _checked: {
        borderColor: "brand.500",
        boxShadow: `0px 0px 0px 1px brand.500`,
      },
    },
    Spinner: {
      variants: {
        small: {
          width: "20px",
          height: "20px",
        },
      },
      baseStyle: {
        speed: "0.85s",
        emptyColor: "gray.200",
        color: "brand.500",
        width: "75px",
        height: "75px",
      },
    },
    Button: {
      // 1. We can update the base styles

      variants: {
        base: {
          bg: "brand.500",
          color: "white",
          fontSize: "bold",
          _hover: {
            background: "brand.800",
          },
        },
        secondary: {
          bg: "brand.500",
          color: "white",
          fontSize: "bold",
          _hover: {
            background: "secondary.600",
          },
        },
        white: {
          bg: "white",
          color: "brand.500",
          fontSize: "bold",
          _hover: {
            background: "lightgray",
          },
        },
      },
      defaultProps: {
        size: "lg", // default is md
        variant: "base", // default is solid
      },
    },
    Checkbox: defineMultiStyleConfig({ baseStyle }),
  },
} as ThemeConfig);

export default chakraTheme;
