"use client";
import { createContext } from "react";

export interface Subscription {
  id: string;
  planId: string;
  status: string;
  currentPeriodStart: number;
  currentPeriodEnd: number;
  quantity: number;
  name: string;
}

export type UserStore = {
  user: UserType;
  setUser: any | null;
};

export type UserType = {
  id: string;
  company: string;
  firstName: any;
  lastName: any;
  email: string;
  isAdmin: boolean;
  acceptedTerms: string;
  isSuperAdmin: boolean;
  helpContentSeen: string;
  projectsCreated: string;
  completedOnboarding: any;
  phoneNumber: string;
  _version: number;
  _deleted: any;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  describeRole: string;
};

export type CompanyType = {
  id: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  phoneNumber: string;
  website: string;
  isPaid: any;
  isDemo: any;
  isCondoit: boolean;
  logoImage: any;
  invoiceExports: any;
  activeSubscription: boolean;
  stripeCustomerId: string;
  stripeSubscriptionId: string;
  stripeSubscriptionObject: string;
  subscriptionType: any;
  subscriptionSeats: any;
  completedOnboarding: any;
  _version: number;
  _deleted: any;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  describeCompany: string;
  trialExpirationDate: string;
};

export type CompanyStore = {
  company: CompanyType;
  setCompany: any | null;
};

export interface IPlan extends Subscription {
  amount: number;
  productId: string;
  paymentMethodUrl: string;
}

export const initialUserValues = {
  id: null,
  company: null,
  firstName: null,
  lastName: null,
  email: null,
  isAdmin: null,
  isSuperAdmin: null,
  helpContentSeen: null,
  projectsCreated: null,
  phoneNumber: null,
  completedOnboarding: null,
  _version: null,
  _deleted: null,
  _lastChangedAt: null,
  createdAt: null,
  updatedAt: null,
  describeRole: null,
};

export const initialCompanyValues = {
  id: null,
  name: null,
  address1: null,
  address2: null,
  city: null,
  state: null,
  postalCode: null,
  phoneNumber: null,
  website: null,
  isPaid: null,
  isDemo: null,
  isCondoit: null,
  logoImage: null,
  invoiceExports: null,
  stripeCustomerId: null,
  stripeSubscriptionId: null,
  stripeSubscriptionObject: null,
  activeSubscription: null,
  subscriptionType: null,
  subscriptionSeats: null,
  completedOnboarding: null,
  _version: null,
  _deleted: null,
  _lastChangedAt: null,
  createdAt: null,
  updatedAt: null,
  describeCompany: null,
  trialExpirationDate: "",
};

export const initialPlanValues = {
  id: "",
  planId: "",
  status: "",
  currentPeriodStart: 0,
  currentPeriodEnd: 0,
  productId: "",
  quantity: 0,
  name: "",
  amount: 0,
};

export const PlanContext = createContext({
  plan: initialPlanValues,
  setPlan: (value: any) => {},
});

const Context = () => {
  return <>Context</>;
};

export default Context;
