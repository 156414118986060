"use client";
/* eslint-disable @next/next/no-img-element */
import "@/styles/globals.css";

import { useContext, useState } from "react";

import { PlanContext, initialPlanValues } from "@/app/contexts";
import { Flex } from "@chakra-ui/react";

import { usePathname, useRouter } from "next/navigation";

import { CurrentUserContext } from "@/app/contexts/currentUserContext";

import { useAuthenticator } from "@/hooks/useAuth";
import { uiEnvironment } from "@condoit-apps/config/src";
import { useQueryClient } from "@tanstack/react-query";
import { App, Drawer, Grid, Layout, theme } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";
import { motion } from "framer-motion";
import { isNil } from "lodash";
import CondoitHeader from "./Header";
import SidebarMenu from "./SidebarMenu";
import { CloseOutlined } from "@ant-design/icons";

const { useBreakpoint } = Grid;

interface LayoutProps {
  children: React.ReactNode;
  signOut: any;
}

const SideMenuWidth = 260;

export const LayoutWrapper = ({ children, signOut }: LayoutProps) => {
  const breakpoints = useBreakpoint();
  const isMobile = !breakpoints.md;
  const [sidebarOpen, setSidebarOpen] = useState(!isMobile);
  const { currentUser, currentCompany } = useContext(CurrentUserContext);
  const { authStatus } = useAuthenticator();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const pathname = usePathname();

  if (uiEnvironment.PKEY && currentUser?.id && typeof window !== "undefined") {
    (window as any).pendo.initialize({
      visitor: {
        id: currentUser?.email,
        "First Name": currentUser?.firstName,
        "Last Name": currentUser?.lastName,
      },
      account: {
        id: currentCompany?.id,
        "Company Name": currentCompany?.name,
      },
    });
  }

  const getStartedRoute = pathname?.startsWith("/get-started");

  const showSidebarMenu = !getStartedRoute && !isNil(currentUser?.acceptedTerms);

  return showSidebarMenu ? (
    <>
      <Layout style={{ minHeight: "100vh", backgroundColor: "black" }}>
        <motion.div
          animate={{
            x: 0,
            opacity: 1,
            transition: {
              duration: 0.5,
            },
          }}
          initial={{
            x: -250,
            opacity: 0,
          }}
          exit={{
            x: -250,
            opacity: 0,
            transition: {
              duration: 0.5,
            },
          }}
        >
          <Sider
            style={{
              height: "100vh",
            }}
            collapsible={true}
            collapsed={isMobile}
            width={SideMenuWidth}
            trigger={null}
            collapsedWidth="0"
          >
            <div
              style={{
                overflow: "auto",
                height: "100vh",
                position: "fixed",
                //for some reason if the width doesn't overflow the container then the svg sld will be blurry on safari
                width: SideMenuWidth + 10,
              }}
            >
              <SidebarMenu isMobile={isMobile} setSidebarOpen={setSidebarOpen} signOut={signOut} />
            </div>
          </Sider>
        </motion.div>
        <Layout
          style={{
            zIndex: 2,
            minHeight: "100vh",
          }}
        >
          <motion.div
            animate={{
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            }}
            initial={{
              y: -50,
              opacity: 0,
            }}
            exit={{
              y: -50,
              opacity: 0,
              transition: {
                duration: 0.5,
              },
            }}
          >
            <Header className="background-white p-0" style={{ padding: 0, backgroundColor: "#ffffff" }}>
              <CondoitHeader showSidebarMenu={sidebarOpen} setSidebarOpen={setSidebarOpen} signOut={signOut} />
            </Header>
          </motion.div>
          <Content>
            <div
              style={{
                minHeight: 360,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                maxWidth: "100%",
                overflow: "auto",
              }}
            >
              {children}
            </div>
          </Content>
          <Drawer
            onClose={() => setSidebarOpen(false)}
            open={sidebarOpen}
            placement="left"
            style={{
              backgroundColor: "black",
            }}
            closeIcon={<CloseOutlined style={{ color: "white" }} />}
            styles={{ header: { alignSelf: "flex-end" } }}
          >
            <SidebarMenu isMobile={isMobile} setSidebarOpen={setSidebarOpen} signOut={signOut} />
          </Drawer>
        </Layout>
      </Layout>
    </>
  ) : (
    <App>{children}</App>
  );
};

interface Props {
  children: React.ReactNode;
}

const AppLayout = (props: Props) => {
  // @ts-expect-error layout is a prototype property and dynamic
  const Layout = props.children?.type?.layout;
  const router = useRouter();
  const queryClient = useQueryClient();
  const { setPlan } = useContext(PlanContext);

  if (Layout != null) {
    return (
      <Flex width="100vw" height={"100vh"} px={0} m={0} p={0} justifyContent="center" alignItems={"center"}>
        {props.children}
      </Flex>
    );
  }

  return (
    <LayoutWrapper
      signOut={() => {
        router.push("/auth/signout");
        queryClient.clear();
        setPlan(initialPlanValues);
      }}
    >
      <App>{props.children}</App>
    </LayoutWrapper>
  );
};

export default AppLayout;
