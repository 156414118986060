import type { ReportHtmlOptions } from "../../../../condoit-types/index";

export const hiddenReports = ["name", "installDate"];

export const equipmentOptions: Partial<ReportHtmlOptions> = {
  includeCoverPage: true,
  includeSitePlan: false,
  includeSitePlanLegend: false,
  includeSitePhotos: false,
  includeFieldGroups: false,
  includeCollectionGroups: true,
  includeEquipment: true,
  includeEquipmentPhotos: true,
  title: "Equipment Report",
};

export const BOMOptions: Partial<ReportHtmlOptions> = {
  includeBillOfMaterials: true,
  includeFirstPageHeader: true,
  includeArbitraryLines: false,
  title: "Bill of Materials",
};

export const siteWalkOptions: Partial<ReportHtmlOptions> = {
  includeCoverPage: true,
  includeSld: false,
  includeSitePlan: true,
  includeSitePlanLegend: true,
  includeSitePhotos: true,
  includeFieldGroups: true,
  includeCollectionGroups: true,
  includeEquipment: true,
  includeEquipmentPhotos: true,
  title: "Site Walk Report",
  shouldShowEmptySections: false,
  shouldShowEmptyFields: false,
};

export const condoitReportOptions: Partial<ReportHtmlOptions> = {
  includeCoverPage: true,
  includeSld: true,
  includeSitePlan: true,
  includeSitePhotos: true,
  includeFieldGroups: true,
  includeCollectionGroups: true,
  includeEquipment: true,
  includeBillOfMaterials: true,
  includeFirstPageHeader: true,
  title: "Condoit Report",
  includeArbitraryLines: false,
};

export const ReportKeys = {
  siteWalk: "Site Walk",
  billOfMaterials: "Bill of Materials",
  equipment: "Equipment",
  condoitReport: "Condoit Report",
  sld: "Single Line Diagram",
};
