export enum FormSubmissionStatus {
  InProgress = "in_progress",
  Submitted = "submitted",
}
import { Prisma } from "@prisma/client";

// biome-ignore lint/performance/noBarrelFile: <explanation>
export * from "@prisma/client";

export type ReadonlyUser = {
  readonly: true;
  email: string;
  projectId?: string;
  equipmentId?: string;
  qrToken?: string;
};

export type User = Prisma.UsersGetPayload<{
  include: {
    notificationPreferences: {
      include: {
        NotificationPreferences: true;
      };
    };
    companies: {
      include: {
        subscriptions: {
          include: PlanPermissionsPath;
        };
      };
    };
    department: true;
    userDevices?: true;
  };
}>;

export type PlanPermissionsPath = {
  plan: {
    include: {
      planPermissions: {
        include: {
          permission: true;
        };
      };
    };
  };
};
