import { CurrentUserContext } from "@/app/contexts/currentUserContext";
import { Box, Flex } from "@chakra-ui/react";
import { Menu } from "@headlessui/react";
import { Bars3BottomLeftIcon, CogIcon } from "@heroicons/react/24/outline";
import { FaSignOutAlt } from "@react-icons/all-files/fa/FaSignOutAlt";
import { FaUserCircle } from "@react-icons/all-files/fa/FaUserCircle";
import { HiRefresh } from "@react-icons/all-files/hi/HiRefresh";
import { type ReactNode, useContext } from "react";
import { resetDemoCompany } from "@/app/apiCalls/company/demo";
import { Button, Col, Dropdown, type MenuProps, Space } from "antd";
import Link from "next/link";

type NavigationItem = {
  name: string;
  href: string | null;
  requiresAdmin: boolean;
  icon: ReactNode;
  action?: () => void;
};

const CondoitHeader = ({ showHeader = true, showSidebarMenu, setSidebarOpen, signOut }) => {
  const { currentUser, isDemoAccount } = useContext(CurrentUserContext);

  const navigationItems: NavigationItem[] = [
    {
      name: "My Profile",
      href: "/profile/user",
      requiresAdmin: false,
      icon: <CogIcon width={14} />,
    },
    {
      name: "Sign out",
      href: "/auth/signout",
      action: () => {
        signOut();
      },
      icon: <FaSignOutAlt width={14} />,
      requiresAdmin: false,
    },
  ];

  if (isDemoAccount) {
    navigationItems.push({
      name: "Reset Demo",
      href: null,
      action: async () => {
        await resetDemoCompany();
        window.location.reload();
      },
      icon: <HiRefresh width={14} />,
      requiresAdmin: false,
    });
  }

  const userNavMenu: MenuProps["items"] = [];
  navigationItems.forEach((item, index) => {
    userNavMenu.push({
      key: index.toString(),
      label: item.href ? <Link href={item.href}>{item.name}</Link> : item.name,
      onClick: item.action,
      icon: item.icon,
    });
  });

  userNavMenu.push({
    type: "divider",
  });

  userNavMenu.push({
    key: "100",
    label: currentUser?.email,
    disabled: true,
  });

  if (!showHeader) {
    return null;
  }

  return (
    <div className="mx-auto flex w-full flex-col" style={{ background: "#F3F4F6", maxWidth: "100vw" }}>
      <div
        className="sticky top-0 z-10 flex h-16 flex-shrink-0 border-b border-gray-200 "
        style={{ background: "white" }}
      >
        <Col xs={12} sm={12} md={0} style={{ zIndex: 10 }}>
          <div className="flex h-full items-center justify-start">
            <Button
              style={{
                borderLeft: "none",
                borderBottom: "none",
                borderTop: "none",
                borderRadius: 0,
                height: "100%",
              }}
              onClick={() => setSidebarOpen(!showSidebarMenu)}
            >
              <Bars3BottomLeftIcon className="h-6 w-6 min-w-6 cursor-pointer" />
            </Button>
          </div>
        </Col>
        <Col xs={12} sm={12} md={24}>
          <div className={"background bg-teal mr-3 flex items-center justify-end lg:ml-6"}>
            {/* {isMobile && <LogoButton />} */}
            {/* Profile dropdown */}
            <Menu as="div" className="relative ">
              <div>
                <Flex gap={3}>
                  <Dropdown menu={{ items: userNavMenu }}>
                    <Space>
                      <>Welcome, {currentUser?.firstName} </>
                      <Box h={30}>
                        <FaUserCircle size="30px" />
                      </Box>
                    </Space>
                  </Dropdown>
                </Flex>
              </div>
            </Menu>
          </div>
        </Col>
      </div>
    </div>
  );
};

export default CondoitHeader;
